import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Aeropuerto from './pages/aeropuerto/Aeropuerto';
import FormAppView from './pages/aeropuerto/FormAppView';
import FinishApp from './pages/aeropuerto/FinishApp';
import { LanguageProvider } from './hooks/languageHook/LanguageHook';
import PageNotFound from "./pages/pageNotFound/PageNotFound";

const App = () => {
  return (
    <LanguageProvider>
      <Router>
        <main className='flipando'>
          <Routes>
            <Route path="/" element={<Aeropuerto />} />
            <Route path="/:type" element={<FormAppView />} />
            <Route path="/finish" element={<FinishApp />} />
            <Route path="/finish/:token" element={<FinishApp />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </main>
      </Router>
    </LanguageProvider>
  );
}

export default App;
