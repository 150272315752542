import React from "react";
import { NavLink } from 'react-router-dom';

import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import { ReactComponent as LogoFlipando } from '../../assets/logoFlipando.svg';
import languageTexts from '../../languajeConstant';
import gifBackground from '../../assets/Popup-bg.png';

const InfoModal = ({title, description, closeModal, isCreateAccountModal}) => {
  const { language } = useLanguage();
  const backgroundStyle = {
    backgroundImage: `url(${gifBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  return (
    <>
    <div className="fl-aeropuerto-modal-background"></div>
    <section style={isCreateAccountModal ? backgroundStyle : {}} className="fl-aeropuerto-modal">
          <div className="fl-aeropuerto-modal-container">

              <button className="fl-aeropuerto-modal-close" onClick={closeModal}>
                  <CloseIcon />
              </button>
              {isCreateAccountModal ?
                  <LogoFlipando className='fl-aeropuerto-modal-logoFlipando' />
                  :
                  <header className="fl-aeropuerto-modal-header">
                      <div className='fl-aeropuerto-breadcrumb'>
                          <span>
                              {title}
                          </span>
                      </div>
                      <p className="fl-aeropuerto-subtitle">{languageTexts[language].aeropuerto.general.Title} <LogoFlipando className='fl-aeropuerto-logoFlipando' /></p>
                  </header>}
              <p className={`fl-aeropuerto-modal-content ${isCreateAccountModal ? 'fl-aeropuerto-modal-content-iscreateaccount' : ''}`}>{description}</p>
              {isCreateAccountModal &&
                  <NavLink className="fl-aeropuerto-modal-btn" to='https://app.flipando.ai/register'>
                      {languageTexts[language].aeropuerto.general.modalUniteBtn}
                  </NavLink>}
          </div>
      </section></>
  );
}

export default InfoModal;