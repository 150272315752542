import React, { useState } from 'react';
import './socialShare.scss';
import clipboardCopy from 'clipboard-copy';
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappIcon,
  TwitterIcon,
  FacebookIcon,
  TelegramIcon
} from 'react-share';
import { ReactComponent as CopyText } from '../../assets/copy-icon.svg';

const SocialShare = ({ url, title, showSocialShare, onHideShareOptions, textToClipboard }) => {
  const [touchStartY, setTouchStartY] = useState(null);
  const [isCopied, setIsCopied] = useState(false);

  const handleTouchStart = (e) => {
    setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    const touchMoveY = e.touches[0].clientY;
    const diff = touchMoveY - touchStartY;

    if (diff > 50) {
      onHideShareOptions();
      setTouchStartY(null);
      setIsCopied(false);
    }
  };

  const handleCopyClick = () => {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(textToClipboard, 'text/html');
    const plainText = parsedHtml.body.textContent;

    console.log('HAANDLE COPY -> plainText', plainText)

    clipboardCopy(plainText);
    setIsCopied(true);
};

  return (
    <div className={`social-share-container ${showSocialShare ? 'visible' : ''}`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}>
      <div className='social-share-container-content'>
        <div className='closing-rectangle'></div>
        <div className='social-share-container-content-buttons'>
          <FacebookShareButton url={url} quote={title}>
            <FacebookIcon size={32} round />
            <span>Facebook</span>
          </FacebookShareButton>
          <TwitterShareButton url={url} title={title}>
            <TwitterIcon size={32} round />
            <span>Twitter</span>
          </TwitterShareButton>
          <WhatsappShareButton url={url} title={title}>
            <WhatsappIcon size={32} round />
            <span>Whatsapp</span>
          </WhatsappShareButton>
          <TelegramShareButton url={url} title={title}>
            <TelegramIcon size={32} round />
            <span>Telegram</span>
          </TelegramShareButton>
          <button className='social-share-container-content-buttons-btn' onClick={handleCopyClick}>
            <CopyText />
            <span>Copiar texto</span>
          </button>
        </div>
        {isCopied && <p>Text copied to clipboard!</p>}
      </div>
    </div>
  );
};

export default SocialShare;
