import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import InfoModal from "./InfoModal";

import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import { ReactComponent as LogoFlipando } from '../../assets/logoFlipando.svg';
import { ReactComponent as LangIcon } from '../../assets/lang-icon.svg';
import { ReactComponent as ArrowIcon } from '../../assets/arrow-icon.svg';
import { ReactComponent as BackIcon } from '../../assets/back.svg';
import gifBackground from '../../assets/bg3.png';


const NavBar = ({language, languageTexts, title, openModal, isFromToken, hasBackButton}) => {
    const [openModalInformation, setOpenModalInformation] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const { toggleLanguage } = useLanguage();
    const navigate = useNavigate();

    const backgroundStyle = {
      backgroundImage: `url(${gifBackground})`,
      backgroundSize: 'cover',
    };


    const handleToggleMenu = () => {
      setMenuOpen(!menuOpen);
    };
    const handleCloseeMenu = () => {
        setMenuOpen(false);
    } ;
    const openModalNavigation = () => {
      setOpenModalInformation(true);
    };
    const closeModal = () => {
      setOpenModalInformation(false);
      setMenuOpen(false);
    };
  const handleChangeLanguage = (newLanguage) => {
    toggleLanguage(newLanguage);
    handleCloseeMenu();
  };
  const goBack = () => {
    if (isFromToken) {
      navigate('/');
    } else {
      navigate(-1);
    }
  };

  return(
    <>
    <aside style={menuOpen ? backgroundStyle : {}}  className={`menu menu-internal ${menuOpen ? "open" : ""}`}>
      {!menuOpen && (
        <section className="fl-aeropuerto-nav-buttons">
          {hasBackButton &&
            <button className="fl-aeropuerto-nav-btn-back" onClick={goBack}>
              <BackIcon />
            </button>
          }
          <button className="fl-aeropuerto-btn-info" onClick={openModal}>
              <InfoIcon />
          </button>
        </section>
      )}
      {menuOpen ?
      <div className='menu-toggle open' onClick={handleToggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      
      :
      <button className="fl-aeropuerto-btn-lang" onClick={handleToggleMenu}>
        <LangIcon />
        {languageTexts[language].aeropuerto.general.languaje}
        <ArrowIcon className="fl-aeropuerto-btn-lang-arrow" />
      </button>
      }
      <div className={`menu-internal-content ${menuOpen ? "open" : ""}`}>
          <ul>
              <li>
              <button
                className='navbar-item'
                onClick={() => handleChangeLanguage('es')}
                disabled={language === 'es'}
              >
              {languageTexts[language].aeropuerto.general.Es}
              </button>
            </li>
            <li>
              <button
                className='navbar-item'
                onClick={() => handleChangeLanguage('en')}
                disabled={language === 'en'}
              >
              {languageTexts[language].aeropuerto.general.In}
              </button>
            </li>
            <li>
              <button
                className='navbar-item'
                onClick={() => handleChangeLanguage('pt')}
                disabled={language === 'pt'}
              >
                {languageTexts[language].aeropuerto.general.Po}
              </button>
            </li>
            <li>
              <button className={`navbar-item `} onClick={openModalNavigation}>
                {languageTexts[language].aeropuerto.general.Unite} <LogoFlipando />
              </button>
            </li>
          </ul>
      </div>
    </aside>
    <div className='fl-aeropuerto-breadcrumb'>
        <span>
          {title}
        </span>
    </div>
    <p className="fl-aeropuerto-subtitle">
      {languageTexts[language].aeropuerto.general.Title} 
      <LogoFlipando />
    </p>

    {openModalInformation &&
      <InfoModal 
        description={languageTexts[language].aeropuerto.general.modalUniteTxt}
        closeModal={closeModal}
        isCreateAccountModal
      />
    }
    </>
  )
}

export default NavBar;
