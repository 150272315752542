import React from 'react';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import languageTexts from '../../languajeConstant';

import { ReactComponent as LogoFlipando } from '../../assets/logoFlipando.svg';
import AeropuertoLogo from '../../assets/logoAeropuerto.png';
import bgDesktop from '../../assets/bg-desktop.png';

function DesktopView() {
    const desktopBackground = {
      backgroundImage: `url(${bgDesktop})`,
      backgroundSize: 'cover',
    };
    const { language } = useLanguage();

    return (
        <main style={desktopBackground} className="aeropuerto-desktop">
            <h1>{languageTexts[language].aeropuerto.desktop.Title}</h1>
            <p>{languageTexts[language].aeropuerto.desktop.Text}</p>
            <h2>
            {languageTexts[language].aeropuerto.general.Title}
            <LogoFlipando />
            <img src={AeropuertoLogo} alt="logo aeropuerto" />
            </h2>
        </main>
    );
}

export default DesktopView;
