

export async function openStream(appId, inputValues) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/airport/${appId}/stream`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Cache-Control': 'no-cache',
            'Connection': 'keep-alive',
        },
        body: JSON.stringify({ inputs: inputValues }),
    });

    return response.body.pipeThrough(new TextDecoderStream()).getReader();
};
