import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import './aeropuerto.scss';
import NavBar from "./navbar";
import InfoModal from "./InfoModal";
import { ReactComponent as ArrowFoward } from '../../assets/icon _arrow forward.svg';
import AeropuertoLogo from '../../assets/logoAeropuerto.png';

import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import languageTexts from '../../languajeConstant';
import gifBackground from '../../assets/bg1.png';
import DesktopView from "./DesktopView";

const Aeropuerto = () => {
  const [openModalInformation, setOpenModalInformation] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const backgroundStyle = {
    backgroundImage: `url(${gifBackground})`,
    backgroundSize: 'cover',
    height: '100%',
  };
  const { language } = useLanguage();
  const openModal = () => {
    setOpenModalInformation(true);
  };
  const closeModal = () => {
    setOpenModalInformation(false);
  };
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const isMobile = windowWidth <= 500;

  return (
    isMobile ?
      <main style={backgroundStyle} className="fl-aeropuerto">
        <NavBar 
          language={language} 
          languageTexts={languageTexts} 
          title={languageTexts[language].aeropuerto.pgMain.subTitle} 
          openModal={openModal} 
        />
        <section className='fl-aeropuerto-main'>
          <h1>{languageTexts[language].aeropuerto.pgMain.Title}</h1>
          <NavLink to="/departure" className="fl-aeropuerto-main-btn">
            {languageTexts[language].aeropuerto.pgMain.BtnTxt}
            <ArrowFoward />
          </NavLink>
          <NavLink to="/arrival" className="fl-aeropuerto-main-btn">
            {languageTexts[language].aeropuerto.pgMain.BtnTxtUyu}
            <ArrowFoward />
          </NavLink>
          <div className="fl-aeropuerto-main-logoAero-container">
            <img src={AeropuertoLogo} className='fl-aeropuerto-main-logoAerop' alt="logo aeropuerto" />
          </div>
        </section>
        {openModalInformation &&
          <InfoModal 
            title={languageTexts[language].aeropuerto.pgMain.subTitle}
            description={languageTexts[language].aeropuerto.pgMain.modalTxt}
            closeModal={closeModal}
          />
        }
      </main>
    :
    <DesktopView />
  );
  
}

export default Aeropuerto;