import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SocialShare from '../../components/socialShare/SocialShare';
import InfoModal from "./InfoModal";
import NavBar from "./navbar";

import { getAirportAppResponse } from '../../api/applications';
import { openStream } from '../../api/streamingResponse';
import gifBackground from '../../assets/bg3.png';
import AeropuertoLogo from '../../assets/logoAeropuerto.png';
import { ReactComponent as ShareIcon } from '../../assets/share-icon.svg';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';
import languageTexts from '../../languajeConstant';
import DesktopView from './DesktopView';

const FinishApp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [result, setResult] = useState('');
  const [title, setTitle] = useState('');
  const [textToCopy, setTextToCopy] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState("");
  const [isFromToken, setIsFromToken] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const contentRef = useRef(null);
  const { token: urlToken } = useParams();
  const { search } = useLocation();
  const [openModalInformation, setOpenModalInformation] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const openModal = () => {
    setOpenModalInformation(true);
  };
  const closeModal = () => {
    setOpenModalInformation(false);
  };

  const startedRef = useRef(false);

  const backgroundStyle = {
    backgroundImage: `url(${gifBackground})`,
    backgroundSize: 'cover',
    height: '100%',
  };
  const { language } = useLanguage();
  const queryParams = new URLSearchParams(search);
  const origin = queryParams.get('origin');
  const appInputs = location.state?.inputs
  const appId = location.state?.appId;
  const [shareUrl, setShareUrl] = useState('');



  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const isMobile = windowWidth <= 500;

  useEffect(() => {
    if (origin === 'departure') {
      setTitle('departure');
    } else if (origin === 'arrival') {
      setTitle('arrival');
    }
  }, [origin]);

  useEffect(() => {
    const fetchData = async () => {
      if (urlToken) {
        try {
          const response = (await getAirportAppResponse(urlToken));
          setResult(response.data.results.output);
          setIsFromToken(true);
        } catch (e) {
          setError(e);
        }
      }
      else if (location.state?.inputs && location.state?.appId && !startedRef.current) {
        handleStreamResponse()
      }
    };
    fetchData();
  }, [location.state, urlToken]);

  const handleShareClick = () => {
    setShowShareOptions(true);
  }
  const hideShareOptions = () => {
    setShowShareOptions(false);
  }

  const handleStreamResponse = async () => {
    startedRef.current = true;
    const stream = await openStream(appId, appInputs);
    try {
      let finalTaskId = null;
      await new Promise(resolve => setTimeout(resolve, 500));
      while (true) {
        const { value, done } = await stream.read();

        if (done) break;
        if (!value) continue;
        if (value.startsWith("id: ")) {
          finalTaskId = value.replace("id: ", "").trim();
          break;
        }

        setResult(prev => prev + value);
        setTimeout(() => {
          if (contentRef.current) {
            contentRef.current.scrollTop = contentRef.current.scrollHeight;
          }
        }, 0);

        await new Promise(resolve => setTimeout(resolve, 5));
      }
      if (finalTaskId) {
        setToken(finalTaskId);
      }
    } catch (error) {
      console.error("Stream reading error:", error);
    }
  };

  useEffect(() => {
    if (!urlToken && token) {
      setTextToCopy(result.replace(/<\/?[^>]+(>|$)/g, ""));
      setShareUrl(`${window.location.origin}/finish/${token}?${title === 'departure' ? 'origin=departure' : 'origin=arrival'}`)
    }
  }, [token, urlToken]);
  return (
    isMobile ?
      <main style={backgroundStyle} className="fl-aeropuerto">
        <NavBar
          language={language}
          languageTexts={languageTexts}
          title={languageTexts[language].aeropuerto.pgMain.subTitle}
          openModal={openModal}
          isFromToken={isFromToken}
          hasBackButton />
        <section ref={contentRef} className="fl-aeropuerto-response" dangerouslySetInnerHTML={{ __html: result }}></section>
        <section className="fl-aeropuerto-response-buttons">
          <button className="fl-aeropuerto-response-buttons-btn" onClick={() => navigate('/')}>
            {languageTexts[language].aeropuerto.result.BtnRestart}
          </button>
          {!isFromToken &&
            <button className="fl-aeropuerto-response-buttons-btn" onClick={() => handleShareClick()}>
              {languageTexts[language].aeropuerto.result.BtnShare} <ShareIcon />
            </button>
          }
        </section>
        {error && <p>{error}</p>}
        <div className="fl-aeropuerto-response-logoAero-container">
          <img src={AeropuertoLogo} className='fl-aeropuerto-main-logoAerop' alt="logo aeropuerto" />
        </div>
        {!isFromToken &&
          <SocialShare
            url={shareUrl}
            title="¡Descubrí mis recomendaciones de viaje hechas con IA!"
            showSocialShare={showShareOptions}
            onHideShareOptions={hideShareOptions}
            textToClipboard={textToCopy}
          />
        }
        {openModalInformation &&
          <InfoModal
            title={languageTexts[language].aeropuerto.pgMain.subTitle}
            description={languageTexts[language].aeropuerto.pgMain.modalTxt}
            closeModal={closeModal}
          />
        }
      </main>
      :
      <DesktopView />
  );
};

export default FinishApp;
