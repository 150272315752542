const languageTexts = {
    es: {
        aeropuerto: {
            desktop:{
                Title: 'Esta es una app móvil',
                Text: 'Desbloquea todo el potencial de esta app móvil en tu dispositivo \n y descubre experiencias impulsadas por IA al alcance de tu mano.'
            },
            general: {
                Title:'Potenciado por',
                Es: 'Español',
                In: 'Inglés',
                Po: 'Portugués',
                Unite: 'Unite a ',
                btnSubmit: '¡Vamos!',
                languaje: 'Idioma',
                modalUniteBtn: 'Unite ahora',
                modalUniteTxt: 'Generando disrupción: construyendo la próxima generación de aplicaciones GenAI. + 1200 aplicaciones GenAI personalizadas',
                loading: '¡Creando recomendaciones de viaje para tu próximo destino!',
            },
            pgMain: {
                Title: 'Tu guía de viaje personalizada.\n ¡Que comience la aventura!',
                subTitle: 'Asistente de viaje con Inteligencia Artificial',
                BtnTxt: 'Conocé más sobre tu destino',
                BtnTxtUyu: 'Descubrí Uruguay',
                modalTxt: 'Esta aplicación te permite diseñar tu viaje y recibir recomendaciones personalizadas. Todo lo que necesitas hacer es proporcionar información sobre tu tipo de viaje y tus preferencias, y verás cómo se despliega la magia. Prepárate para una aventura única y adaptada a tus gustos impulsada por Inteligencia Artificial.'
            },
            result: {
                BtnShare: 'Compartir',
                BtnRestart: 'Comenzar de vuelta'
            }
        },
        appCard: {
            by: 'By',
            created: 'Created',
            run: 'Run',
            time: 'time',
            times: 'times'
        },
    },
    en: {
        aeropuerto: {
            desktop:{
                Title: 'This is a mobile app',
                Text: 'Unlock the full potential of this mobile app on your device \n and discover AI-powered experiences at your fingertips.'
            },
            general: {
                Title:'Powered by',
                Es: 'Spanish',
                In: 'English',
                Po: 'Portuguese',
                Unite: 'Join to ',
                btnSubmit: '¡Go!',
                languaje: 'Language',
                modalUniteBtn: 'Join now',
                modalUniteTxt: 'Generating disruption: building the next generation of GenAI apps. + 1200 custom GenAI apps',
                loading: 'Creating travel recommendations for your next destination!',
            },
            pgMain: {
                Title: 'Your personalized \n travel guide.\n Let the adventure begin!',
                subTitle: 'AI Trip Planner',
                BtnTxt: 'Learn more about your destination',
                BtnTxtUyu: 'Meet Uruguay',
                modalTxt: 'This application allows you to design your trip and receive personalized recommendations. All you need to do is provide information about your type of trip and your preferences, and you will see how the magic unfolds. Get ready for a unique adventure tailored to your tastes powered by Artificial Intelligence.'
            },
            result: {
                BtnShare: 'Share',
                BtnRestart: 'Restart'
            }
        },
        appCard: {
            by: 'Por',
            created: 'Creada',
            run: 'Corrida',
            time: 'vez',
            times: 'veces'
        },
    },
    pt: {
        appCard: {
            by: 'Por',
            created: 'Creada',
            run: 'Corrida',
            time: 'vez',
            times: 'veces'
        },
        aeropuerto: {
            desktop:{
                Título: 'Este é um aplicativo móvel',
                Texto: 'Desbloqueie todo o potencial deste aplicativo móvel no seu dispositivo \n e descubra experiências potenciadas por IA na ponta dos seus dedos.'
            },
            general: {
                Title:'Impulsionado por',
                Es: 'Espanhol',
                In: 'Inglês',
                Po: 'Português',
                Unite: 'Unite a ',
                btnSubmit: 'Vamos!',
                languaje: 'Idioma',
                modalUniteBtn: 'Unite agora',
                modalUniteTxt: 'Gerando disrupção: construindo a próxima geração de aplicativos GenAI. + 1200 aplicativos GenAI personalizados',
                loading: 'Criando recomendações de viagem para o seu próximo destino!',
            },
            pgMain: {
                Title: 'Seu guia de viagem personalizado.\n Que a aventura comece!',
                subTitle: 'Assistente de viagem com Inteligência Artificial',
                BtnTxt: 'Saiba mais sobre o seu destino',
                BtnTxtUyu: 'Conheça o Uruguai',
                modalTxt: 'Este aplicativo permite que você projete sua viagem e receba recomendações personalizadas. Tudo o que você precisa fazer é fornecer informações sobre o tipo de viagem e suas preferências, e verá a mágica acontecer. Prepare-se para uma aventura única e adaptada aos seus gostos, impulsionada por Inteligência Artificial.',
            },
            result: {
                BtnShare: 'Compartilhar',
                BtnRestart: 'Reiniciar'
            }
        },
    },
  };
  
  export default languageTexts;
  