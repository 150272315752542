import axios from "axios";


export async function getAppCompletions(appId, logic, appGeneratorPrivate, file, fileDescription, outputFormat, token) {
  const config = {
    headers: {
      'Authorization': 'Bearer ' + token 
    }
  }

  const formData = new FormData();
  if (file) {
    formData.append('document', file);
    if (fileDescription) {
      formData.append('document_description', fileDescription);
    } 
  }
  formData.append('inputs_in', JSON.stringify(logic));
  formData.append('output_format', outputFormat);
  formData.append('app_generator_private', appGeneratorPrivate);

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/${appId}/completion`, formData, config);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

export async function getAirportApps(type) {
  // Type tiene que ser una string que sea "arrival" (para la app con info sobre uru) o "departure" (para la app con info de otros paises)
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/search/airport/${type}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}


export async function getAirportAppCompletions(appId, logic) {
  const formData = new FormData();
  formData.append('inputs_in', JSON.stringify(logic));

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/${appId}/guest-completion`, formData);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

export async function getAirportAppResponse(token) {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/applications/history/${token}`);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}
