import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import { getAirportApps } from '../../api/applications';
import languageTexts from '../../languajeConstant';
import InfoModal from "./InfoModal";
import NavBar from "./navbar";

import gifBackground from '../../assets/bg2.png';
import loadingAnimation from '../../assets/loadingAnimation.gif';
import AeropuertoLogo from '../../assets/logoAeropuerto.png';
import { useLanguage } from '../../hooks/languageHook/LanguageHook';

const FormAppView = () => {
    const [app, setApp] = useState(null);
    const [currentAppByLanguage, setCurrentAppByLanguage] = useState(null);
    const [appInput, setAppInput] = useState([]);
    const [error,] = useState("");
    const [loading,] = useState(false);
    const navigate = useNavigate();
    const { type } = useParams();
    const [openModalInformation, setOpenModalInformation] = useState(false);
    const openModal = () => {
        setOpenModalInformation(true);
    };
    const closeModal = () => {
        setOpenModalInformation(false);
    };

    const backgroundStyle = {
        backgroundImage: `url(${gifBackground})`,
        backgroundSize: 'cover',
        height: '100%',
    };
    const { language } = useLanguage();

    useEffect(() => {
        const fetchApp = async () => {
            try {
                const resp = await getAirportApps(type);
                setApp(resp.results);
            } catch (error) {
                console.error(error);
            }
        };

        const throttleFetch = () => {
            if (!app && type) {
                fetchApp();
            }
        };

        const throttleDelay = 60000;
        throttleFetch();
        const intervalId = setInterval(throttleFetch, throttleDelay);

        return () => {
            clearInterval(intervalId);
        };
    }, [app, language, type]);

    useEffect(() => {
        if (app != null) {
            switch (language) {
                case 'es':
                    setCurrentAppByLanguage(app.spanish);
                    break;
                case 'en':
                    setCurrentAppByLanguage(app.english);
                    break;
                case 'pt':
                    setCurrentAppByLanguage(app.portuguese);
                    break;
                default:
                    setCurrentAppByLanguage(app.spanish);
                    break;
            }

            setAppInput(currentAppByLanguage?.inputs.map((input) => {
                return {
                    name: input.name,
                    value: "",
                    placeholder: input.value
                };
            }));
        }
    }, [app, currentAppByLanguage?.inputs, language]);

    const handleChangeInputs = (index, event) => {
        setAppInput(prevState => {
            const values = [...prevState];
            values[index].value = event.target.value;
            return values;
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        navigate('/finish', { state: { appId: currentAppByLanguage.id, inputs: appInput, origin: type } });
    };


    return (
        <main style={backgroundStyle} className={`fl-aeropuerto ${loading ? 'fl-aeropuerto-loading' : ''}`}>
            <header>
                <NavBar
                    language={language}
                    languageTexts={languageTexts}
                    title={languageTexts[language].aeropuerto.pgMain.subTitle}
                    openModal={openModal}
                    hasBackButton
                />
            </header>
            {!loading ?
                <>
                    <section className='fl-aeropuerto-arrivals'>
                        <h1>{currentAppByLanguage?.name}</h1>
                        <form className="fl-aeropuerto-form">
                            {appInput?.map((inputObj, index) => (
                                <div key={index} className='field'>
                                    <label className='label'>{inputObj.name}</label>
                                    <input
                                        className='input'
                                        type="text"
                                        name={inputObj.name}
                                        placeholder={inputObj.placeholder}
                                        value={inputObj.value}
                                        onChange={(event) => handleChangeInputs(index, event)} />
                                </div>
                            ))}
                            {app && <button className="fl-aeropuerto-arrivals-button" onClick={handleSubmit}>{languageTexts[language].aeropuerto.general.btnSubmit}</button>}
                        </form>
                        <div className="fl-aeropuerto-main-logoAero-container">
                            <img src={AeropuertoLogo} className='fl-aeropuerto-main-logoAerop' alt="logo aeropuerto" />
                        </div>
                        {error && <p>{error}</p>}
                        {openModalInformation &&
                            <InfoModal
                                title={languageTexts[language].aeropuerto.pgMain.subTitle}
                                description={languageTexts[language].aeropuerto.pgMain.modalTxt}
                                closeModal={closeModal}
                            />
                        }
                    </section>
                </>
                :
                <>
                    <section className="fl-aeropuerto-loading-center">
                        <img src={loadingAnimation} className='fl-aeropuerto-loading-animation' alt="loading animation" />
                        <p className="fl-aeropuerto-loading-text">{languageTexts[language].aeropuerto.general.loading}</p>
                    </section>
                    <div className="fl-aeropuerto-main-logoAero-container">
                        <img src={AeropuertoLogo} className='fl-aeropuerto-main-logoAerop' alt="logo aeropuerto" />
                    </div>
                </>
            }
        </main>
    );
}

export default FormAppView;
